import React from "react";
import { Helmet } from "react-helmet"
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { getImage } from "gatsby-plugin-image";
import Layout from "../components/Layout";


// eslint-disable-next-line
export const DavetiyeKatalogPageTemplate = ({
  image,
  heading,



}) => {
  const heroImage = getImage(image) || image;


  return (
    <div className="content">
        <Helmet>
          <title>Davetiye ve Katalog | Volkan Matbaa</title>
          <meta name="description" content="Nişan ve Düğün Davetiyeleri. İklim,Erdem,Sedef,Armoni,Emniyet,Cem Kataloglari" />
        </Helmet>
      <section className="section section--gradient">
        <div className="container">
          <div className="section">
            <div className="columns">
              <div className="column is-7 is-offset-1">
                <h3 className="has-text-weight-semibold is-size-2 ">
                  {heading}
                </h3>
                <p className="notification is-info">Listelenen Katalog ve Davetiye Fiyatları Firmamızda Aynen Geçerlidir</p>
              </div>
            </div>
            <div className="columns">
              <div className="column is-10 is-offset-1">

              <h3><a href="https://www.iklimdavetiye.com/cards.html" rel="nofollow noopener">İklim Davetiye</a></h3>
                <figure class="image is-3by5">
                  <iframe class="has-ratio" title="iklim davetiye katalog" loading="lazy" width="900" height="800" src="https://www.iklimdavetiye.com/cards.html" frameborder="0" allowfullscreen></iframe>
                </figure>
                <hr></hr>
                <h3><a href="https://www.erdemdavetiye.com/katalog/001/erdem-davetiye" rel="nofollow noopener">Erdem Davetiye</a></h3>
                <figure class="image is-3by5">
                  <iframe class="has-ratio" title="erdem davetiye katalog" loading="lazy" width="900" height="800" src="https://www.erdemdavetiye.com/katalog/001/erdem-davetiye" frameborder="0" allowfullscreen></iframe>
                </figure>
                <hr></hr>
               
                <h3><a href="https://www.sedefcards.com/urunler" rel="nofollow noopener">Sedef Davetiye</a></h3>
                <figure class="image is-3by5">
                  <iframe class="has-ratio" title="sedef davetiye katalog" loading="lazy" width="900" height="800" src="https://www.sedefcards.com/urunler" frameborder="0" allowfullscreen></iframe>
                </figure>
                <hr></hr>
                <h3><a href="https://www.armonidavetiye.com.tr/davetiye" rel="nofollow noopener">Armoni Davetiye</a></h3>
                <figure class="image is-3by5">
                  <iframe class="has-ratio" title="armoni davetiye katalog" loading="lazy" width="900" height="800" src="https://www.armonidavetiye.com.tr/davetiye" frameborder="0" allowfullscreen></iframe>
                </figure>
                <hr></hr>
                <h3><a href="https://www.emniyetdavetiye.com/liste=elite-davetiye" rel="nofollow noopener">Emniyet Davetiye</a></h3>
                <figure class="image is-3by5">
                  <iframe class="has-ratio" title="emniyet davetiye katalog" loading="lazy" width="900" height="800" src="https://www.emniyetdavetiye.com/liste=elite-davetiye" frameborder="0" allowfullscreen></iframe>
                </figure>
                <hr></hr>
                <h3><a href="https://www.cemdavetiyeleri.com.tr/davetiye/elegance.html" rel="nofollow noopener">Cem Davetiye</a></h3>
                <figure class="image is-3by5">
                  <iframe class="has-ratio" title="Cem davetiye katalog" loading="lazy" width="900" height="800" src="https://www.cemdavetiyeleri.com.tr/davetiye/elegance.html" frameborder="0" allowfullscreen></iframe>
                </figure>
                <hr></hr>
              </div>
            </div>
          </div>
        </div>
      </section>

    </div>
  );
};

DavetiyeKatalogPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  heading: PropTypes.string,
  description: PropTypes.string,
  intro: PropTypes.shape({
    blurbs: PropTypes.array,
  }),
  main: PropTypes.shape({
    heading: PropTypes.string,
    description: PropTypes.string,
    image1: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    image2: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    image3: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  }),
  testimonials: PropTypes.array,
  fullImage: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  pricing: PropTypes.shape({
    heading: PropTypes.string,
    description: PropTypes.string,
    plans: PropTypes.array,
  }),
};

const DavetiyeKatalog = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout>
      <DavetiyeKatalogPageTemplate
        image={frontmatter.image}
        title={frontmatter.title}
        heading={frontmatter.heading}
        description={frontmatter.description}
        intro={frontmatter.intro}
        fullImage={frontmatter.full_image}

      />
    </Layout>
  );
};

DavetiyeKatalog.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default DavetiyeKatalog;

export const DavetiyeKatalogQuery = graphql`
  query DavetiyeKatalog($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        image {
          childImageSharp {
            gatsbyImageData(quality: 100, layout: FULL_WIDTH)
          }
        }
        heading
        description
        intro {
          blurbs {
            image {
              childImageSharp {
                gatsbyImageData(width: 240, quality: 64, layout: CONSTRAINED)
              }
            }
            descrip
          }
          
          
        }

      }
    }
  }
`;
